import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaTrash } from "react-icons/fa";
import "../styles/Team.css";

const Team = () => {
  const [memberType, setMemberType] = useState("leader");
  const [memberData, setMemberData] = useState({
    image: "",
    name: "",
    designation: "",
    aboutMember: "",
    experience: [{ role: "", company: "", aboutJob: "" }],
  });

  const [team, setTeam] = useState([]);

  const extractFileId = (rawURL) => {
    const RAW_URL1 = rawURL?.split("/d/");
    const RAW_URL2 = RAW_URL1[1]?.split("/view");
    const IMAGE_ID = RAW_URL2[0];
    return `https://drive.google.com/thumbnail?id=${IMAGE_ID}`;
  };

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await axios.get(
          "https://api.lifesignify.com/api/teams"
        );
        setTeam(response.data);
      } catch (error) {
        console.error("Error fetching team data:", error);
      }
    };

    fetchTeamData();
  }, []);

  const handleChange = (e) => {
    setMemberType(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "image") {
      const extractedUrl = extractFileId(value);
      setMemberData({ ...memberData, [name]: extractedUrl });
    } else {
      setMemberData({ ...memberData, [name]: value });
    }
  };

  const handleExperienceChange = (index, e) => {
    const { name, value } = e.target;
    const newExperience = [...memberData.experience];
    newExperience[index][name] = value;
    setMemberData({ ...memberData, experience: newExperience });
  };

  const addExperience = () => {
    setMemberData({
      ...memberData,
      experience: [
        ...memberData.experience,
        { role: "", company: "", aboutJob: "" },
      ],
    });
  };

  const removeExperience = (index) => {
    const newExperience = memberData.experience.filter((_, i) => i !== index);
    setMemberData({ ...memberData, experience: newExperience });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://api.lifesignify.com/api/teams",
        {
          memberType,
          ...memberData,
        }
      );

      const updatedTeam = await axios.get(
        "https://api.lifesignify.com/api/teams"
      );
      setTeam(updatedTeam.data);

      setMemberData({
        image: "",
        name: "",
        designation: "",
        aboutMember: "",
        experience: [{ role: "", company: "", aboutJob: "" }],
      });
    } catch (error) {
      console.error("Error adding team member:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `https://api.lifesignify.com/api/teams/${id}`
      );

      const updatedTeam = await axios.get(
        "https://api.lifesignify.com/api/teams"
      );
      setTeam(updatedTeam.data);
    } catch (error) {
      console.error("Error deleting team member:", error);
    }
  };

  return (
    <div className="team-container">
      <h1>Add Team Member</h1>
      <form onSubmit={handleSubmit} className="team-form">
        <label htmlFor="memberType">Select Member Type:</label>
        <select id="memberType" value={memberType} onChange={handleChange}>
          <option value="leader">Leadership</option>
          <option value="expert">Expert Team</option>
          <option value="support">Support Team</option>
        </select>

        <input
          type="text"
          name="image"
          placeholder="Image URL"
          value={memberData.image}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={memberData.name}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="designation"
          placeholder="Designation"
          value={memberData.designation}
          onChange={handleInputChange}
          required
        />
        <textarea
          name="aboutMember"
          placeholder="About Member"
          value={memberData.aboutMember}
          onChange={handleInputChange}
          required
          style={{ width: "98%", padding: 10, height: 100, outline: "none" }}
        />

        <div>
          <h3>Experience</h3>
          {memberData.experience.map((exp, index) => (
            <div key={index} className="experience-form">
              <input
                type="text"
                name="role"
                placeholder="Role"
                value={exp.role}
                onChange={(e) => handleExperienceChange(index, e)}
                required
              />
              <input
                type="text"
                name="company"
                placeholder="Company"
                value={exp.company}
                onChange={(e) => handleExperienceChange(index, e)}
                required
              />
              <textarea
                name="aboutJob"
                placeholder="About the Job"
                value={exp.aboutJob}
                onChange={(e) => handleExperienceChange(index, e)}
                required
              />
              <button type="button" onClick={() => removeExperience(index)}>
                Remove Experience
              </button>
            </div>
          ))}
          <button
            style={{
              marginTop: 10,
              marginBottom: 10,
              backgroundColor: "black",
            }}
            type="button"
            onClick={addExperience}
          >
            Add Experience
          </button>
        </div>

        <button type="submit" style={{ backgroundColor: "green" }}>
          Add {memberType.charAt(0).toUpperCase() + memberType.slice(1)}
        </button>
      </form>

      <h2>Team Members</h2>
      {team.length === 0 ? (
        <p>No team members found.</p>
      ) : (
        <table className="team-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Image</th>
              <th>Designation</th>
              <th>Member Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {team.map((member, index) => (
              <tr key={index}>
                <td>{member.name}</td>
                <td>
                  <img
                    src={member?.image}
                    alt={member.name}
                    style={{ width: "50px", height: "50px" }}
                  />
                </td>
                <td>{member.designation}</td>
                <td>
                  {member.memberType.charAt(0).toUpperCase() +
                    member.memberType.slice(1)}
                </td>
                <td>
                  <FaTrash
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={() => handleDelete(member._id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Team;
