import React from "react";
import "../styles/Dashboard.css";

const Dashboard = () => {
  const metrics = [
    { title: "Revenue", count: "₹0", color: "#9C27B0" },
    { title: "Users", count: 4, color: "#4CAF50" },
    { title: "Orders", count: 0, color: "#2196F3" },
    { title: "Courses", count: 1, color: "#FF9800" },
    { title: "Books", count: 2, color: "#F44336" },
    { title: "Consultation Participants", count: 1, color: "#3F51B5" },
    { title: "Webinar Participants", count: 2, color: "#fd5c77" },
  ];

  return (
    <div className="dashboard">
      <h1>Dashboard</h1>
      <div className="metrics-container">
        {metrics.map((metric) => (
          <div
            className="metric-card"
            style={{ backgroundColor: metric.color }}
            key={metric.title}
          >
            <h2>{metric.count}</h2>
            <p>{metric.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
