import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Sidebar.css";
import { useUserContext } from "../context/UserContext";
import { ImCross } from "react-icons/im";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const { logout } = useUserContext();

  const handleNavigation = (path) => {
    navigate(`/admin${path}`);
    toggleSidebar();
  };

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="toggle-icon" onClick={toggleSidebar}>
        <img src="/companyLogo.png" alt="logo" className="logo" />
        {isOpen ? <ImCross color="white" size={18} /> : null}
      </div>
      <img src="/companyLogo.png" alt="logo" className="large-logo" />
      <ul className="nav-list">
        <li onClick={() => handleNavigation("/dashboard")}>Dashboard</li>
        <li onClick={() => handleNavigation("/users")}>Users</li>
        <li onClick={() => handleNavigation("/orders")}>Orders</li>
        <li onClick={() => handleNavigation("/courses")}>Courses</li>
        <li onClick={() => handleNavigation("/books")}>Books</li>
        <li onClick={() => handleNavigation("/consultation")}>Consultation</li>
        <li onClick={() => handleNavigation("/webinar")}>Webinar</li>
        <li onClick={() => handleNavigation("/team")}>Team</li>
        <li onClick={() => handleNavigation("/dhyanam")}>Dhyanam</li>
        <li onClick={() => handleNavigation("/testimonial")}>Testimonial</li>
        <li onClick={() => handleNavigation("/admin")}>Admin</li>
      </ul>
      <button onClick={() => logout()} className="logoutBtn">
        Logout
      </button>
    </div>
  );
};

export default Sidebar;
