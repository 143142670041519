import React, { useState, useEffect } from "react";
import "../styles/Orders.css";
import axios from "axios";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [productDetails, setProductDetails] = useState({});

  const fetchOrders = async () => {
    try {
      const response = await fetch("https://api.lifesignify.com/api/orders");
      if (!response.ok) {
        throw new Error("Failed to fetch orders");
      }
      const data = await response.json();
      setOrders(data.orders);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleStatusChange = async (orderId, status) => {
    try {
      const response = await fetch(
        `https://api.lifesignify.com/api/orders/status/${orderId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        fetchOrders();
        alert("Order status updated successfully!");
      } else {
        alert(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error(error);
      alert("Failed to update order status");
    }
  };

  const handleViewDetails = async (orderId) => {
    const order = orders.find((order) => order._id === orderId);
    setSelectedOrder((prevOrder) =>
      prevOrder && prevOrder._id === orderId ? null : order
    );

    if (order) {
      const productDetailsPromises = order.productId.map(async (product) => {
        let response;
        try {
          if (product.itemType === "Book") {
            response = await axios.get(
              `https://api.lifesignify.com/api/books/${product.id}`
            );
          } else if (product.itemType === "Course") {
            response = await axios.get(
              `https://api.lifesignify.com/api/courses/${product.id}`
            );
          } else if (product.itemType === "Dhyanam") {
            response = await axios.get(
              `https://api.lifesignify.com/api/dhyanam/${product.id}`
            );
          }
          return response.data;
        } catch (error) {
          console.error(
            `Failed to fetch product details for ${product.itemType}:`,
            error
          );
        }
      });

      const products = await Promise.all(productDetailsPromises);
      setProductDetails((prevDetails) => ({
        ...prevDetails,
        [orderId]: products,
      }));
    }
  };

  return (
    <div className="orders">
      <h1>Order Management</h1>
      <table className="orders-table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th className="hideSmall">Customer</th>
            <th>Total</th>
            <th>Status</th>
            <th>View Details</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <React.Fragment key={order._id}>
              <tr>
                <td>{order._id}</td>
                <td className="hideSmall">{order.shippingAddress.fullName}</td>
                <td>₹{parseFloat(order.totalAmount) / 100}</td>
                <td>
                  <select
                    value={order.orderStatus}
                    onChange={(e) =>
                      handleStatusChange(order._id, e.target.value)
                    }
                  >
                    <option value="Pending">Pending</option>
                    <option value="Processed">Processed</option>
                    <option value="Dispatched">Dispatched</option>
                    <option value="Out for Delivery">Out for Delivery</option>
                    <option value="Delivered">Delivered</option>
                  </select>
                </td>

                <td>
                  <button
                    className="view-details-button"
                    onClick={() => handleViewDetails(order._id)}
                  >
                    View
                  </button>
                </td>
              </tr>
              {selectedOrder && selectedOrder._id === order._id && (
                <tr>
                  <td colSpan="6">
                    <div className="order-card">
                      <h3>Order Details</h3>
                      <p>
                        <strong>Order ID: </strong>
                        {selectedOrder._id}
                      </p>
                      <p>
                        <strong>User ID: </strong>
                        {selectedOrder.userId}
                      </p>
                      <p>
                        <strong>Transaction ID: </strong>
                        {selectedOrder.transactionId}
                      </p>
                      <p>
                        <strong>Order Placed: </strong>
                        {selectedOrder.createdAt}
                      </p>
                      <p>
                        <strong>Address: </strong>
                        {`${order.shippingAddress.fullName}, ${order.shippingAddress.mobile}, ${order.shippingAddress.street}, ${order.shippingAddress.city}, ${order.shippingAddress.state}, ${order.shippingAddress.country}, ${order.shippingAddress.pincode}`}
                      </p>
                      <p>
                        <strong>Payment Status: </strong>
                        {selectedOrder.paymentStatus}
                      </p>
                      <p>
                        <strong>Order Status: </strong>
                        {selectedOrder.orderStatus}
                      </p>
                      <p>
                        <strong>Total Amount: </strong>₹
                        {parseFloat(selectedOrder.totalAmount) / 100}
                      </p>

                      <h3>Products</h3>
                      <div className="products-list">
                        {productDetails[selectedOrder._id]?.map(
                          (product, index) => (
                            <div key={index} className="product-item">
                              <img
                                src={product.imageUrl ?? product.image}
                                alt={product.name}
                                className="product-image"
                              />
                              <h3>
                                <strong>{product.name}</strong>
                              </h3>
                              <p>₹{parseFloat(product.price)}</p>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Orders;
