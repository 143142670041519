import React, { useState } from "react";
import "../styles/AdminLogin.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useUserContext } from "../context/UserContext";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useUserContext();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://api.lifesignify.com/api/admin/login",
        {
          username: email,
          password,
        }
      );

      // Check if the response contains a valid token
      if (response.status === 200 && response.data.token) {
        const userData = { name: "Admin", email };
        login(userData); // Save user info in context
        navigate("/admin/dashboard"); // Navigate to dashboard
      } else {
        alert("Invalid response received. Please try again.");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          alert(error.response.data.message || "Admin not assigned to login");
        } else if (error.response.status === 401) {
          alert("Invalid password. Please try again.");
        } else if (error.response.status === 404) {
          alert("Admin not found.");
        } else {
          alert("An error occurred. Please try again.");
        }
      } else {
        alert("Network error. Please try again.");
      }
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h1>Admin Login</h1>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
          <a href="#">Forgot Password?</a>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
