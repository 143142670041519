import React, { useState, useEffect } from "react";
import "../styles/Books.css";

const Books = () => {
  const [books, setBooks] = useState([]);
  const [newBook, setNewBook] = useState({
    title: "",
    pdfLink: "",
    imageUrl: "",
    price: "",
  });
  const [editBook, setEditBook] = useState({
    id: "",
    title: "",
    pdfLink: "",
    imageUrl: "",
    price: "",
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedBookId, setSelectedBookId] = useState(null);

  const extractFileId = (rawURL) => {
    const RAW_URL1 = rawURL?.split("/d/");
    const RAW_URL2 = RAW_URL1[1]?.split("/view");
    const IMAGE_ID = RAW_URL2[0];
    return `https://drive.google.com/thumbnail?id=${IMAGE_ID}`;
  };

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await fetch("https://api.lifesignify.com/api/books");
        if (!response.ok) {
          throw new Error("Failed to fetch books");
        }
        const data = await response.json();
        setBooks(data);
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    };
    fetchBooks();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "imageUrl") {
      const imageUrl = extractFileId(value);
      setNewBook({ ...newBook, [name]: imageUrl });
    } else {
      setNewBook({ ...newBook, [name]: value });
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;

    if (name === "imageUrl") {
      const imageUrl = extractFileId(value);
      setEditBook({ ...editBook, [name]: imageUrl });
    } else {
      setEditBook({ ...editBook, [name]: value });
    }
  };

  const handleAddBook = async (e) => {
    e.preventDefault();
    if (newBook.title && newBook.pdfLink && newBook.imageUrl && newBook.price) {
      try {
        const response = await fetch(
          "https://api.lifesignify.com/api/books/create",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newBook),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to add book");
        }

        const addedBook = await response.json();
        setBooks([...books, addedBook]); // Add the new book to the state
        setNewBook({ title: "", pdfLink: "", imageUrl: "", price: "" }); // Reset form
      } catch (error) {
        console.error("Error adding book:", error);
      }
    }
  };

  const handleDeleteBook = async (bookId) => {
    try {
      const response = await fetch(
        `https://api.lifesignify.com/api/books/${bookId}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete book");
      }

      // Remove deleted book from the state
      setBooks(books.filter((book) => book._id !== bookId));
      setShowConfirmModal(false); // Close the modal after deletion
    } catch (error) {
      console.error("Error deleting book:", error);
    }
  };

  const handleUpdateBook = async (e) => {
    e.preventDefault();
    if (
      editBook.title ||
      editBook.price ||
      editBook.imageUrl ||
      editBook.pdfLink
    ) {
      try {
        const response = await fetch(
          `https://api.lifesignify.com/api/books/${editBook.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editBook),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update book");
        }

        const updatedBook = await response.json();
        setBooks(
          books.map((book) =>
            book._id === updatedBook._id ? updatedBook : book
          )
        ); // Update the book in the state
        setEditBook({
          id: "",
          title: "",
          pdfLink: "",
          imageUrl: "",
          price: "",
        }); // Reset edit form
        setShowConfirmModal(false); // Close the modal after update
      } catch (error) {
        console.error("Error updating book:", error);
      }
    }
  };

  const handleEditClick = (bookId) => {
    setSelectedBookId(bookId);
    setShowConfirmModal(true);
  };

  const handleCloseModal = () => {
    setShowConfirmModal(false);
    setEditBook({ id: "", title: "", pdfLink: "", imageUrl: "", price: "" }); // Reset edit form
  };

  return (
    <div className="books">
      <h1>Book Management</h1>
      <form onSubmit={handleAddBook} className="add-book-form">
        <input
          type="text"
          name="title"
          placeholder="Book Title"
          value={newBook.title}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="pdfLink"
          placeholder="PDF Link"
          value={newBook.pdfLink}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="imageUrl"
          placeholder="Image URL"
          value={newBook.imageUrl}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="price"
          placeholder="Price"
          value={newBook.price}
          onChange={handleInputChange}
          required
        />
        <button type="submit">Add Book</button>
      </form>

      <table className="books-table">
        <thead>
          <tr>
            <th className="hideLarge">Book ID</th>
            <th>Title</th>
            <th>Pdf</th>
            <th>Image</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {books.length > 0 ? (
            books.map((book) => (
              <tr key={book._id}>
                <td className="hideLarge">{book._id}</td>
                <td>{book.title}</td>
                <td>
                  <a
                    href={book.pdfLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View PDF
                  </a>
                </td>
                <td>
                  <img
                    src={book?.imageUrl}
                    alt={`${book.title} Cover`}
                    className="thumbnail"
                  />
                </td>
                <td>
                  {["free", "0"].includes(book.price.toLowerCase())
                    ? "Free"
                    : `₹${book.price}`}
                </td>

                <td>
                  <button onClick={() => handleEditClick(book._id)}>
                    Edit
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" style={{ textAlign: "center" }}>
                No books added yet.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showConfirmModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>What do you want to do?</h3>
            <button
              onClick={() => handleDeleteBook(selectedBookId)}
              className="delete-btn"
            >
              Delete
            </button>
            <button
              onClick={() => {
                setShowConfirmModal(false); // Close modal
                setEditBook({
                  id: selectedBookId,
                  title: "",
                  pdfLink: "",
                  imageUrl: "",
                  price: "",
                }); // Pre-fill edit form
              }}
              className="update-btn"
            >
              Update
            </button>
            <button onClick={handleCloseModal} className="close-btn">
              Close
            </button>
          </div>
        </div>
      )}
      {editBook.id && !showConfirmModal && (
        <form onSubmit={handleUpdateBook} className="update-book-form">
          <h3>Edit Book</h3>
          <input
            type="text"
            name="title"
            placeholder="New Title"
            value={editBook.title}
            onChange={handleEditChange}
          />
          <input
            type="text"
            name="price"
            placeholder="New Price"
            value={editBook.price}
            onChange={handleEditChange}
          />
          <input
            type="text"
            name="imageUrl"
            placeholder="New Image URL"
            value={editBook.imageUrl}
            onChange={handleEditChange}
          />
          <input
            type="text"
            name="pdfLink"
            placeholder="New PDF Link"
            value={editBook.pdfLink}
            onChange={handleEditChange}
          />
          <button type="submit">Update Book</button>
        </form>
      )}
    </div>
  );
};

export default Books;
