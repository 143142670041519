import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminLogin from "./components/AdminLogin";
import Main from "./components/Main";

const App = () => {
  const user = localStorage.getItem("user");

  return (
    <div className="app">
      <Routes>
        <Route
          path="/"
          element={user ? <Navigate to="/admin" /> : <AdminLogin />}
        />
        <Route
          path="/admin/*"
          element={user ? <Main /> : <Navigate to="/" />}
        />
      </Routes>
    </div>
  );
};

export default App;
