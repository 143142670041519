import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/Admin.css";

const Admin = () => {
  const [admins, setAdmins] = useState([]);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    try {
      const response = await axios.get(
        "https://api.lifesignify.com/api/admin/"
      );
      setAdmins(response.data);
    } catch (error) {
      console.error("Error fetching admins", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.lifesignify.com/api/admin/",
        formData
      );
      fetchAdmins();
      setFormData({ username: "", password: "" });
    } catch (error) {
      console.error("Error adding admin", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.lifesignify.com/api/admin/${id}`);
      fetchAdmins();
    } catch (error) {
      console.error("Error deleting admin", error);
    }
  };

  const handleAssignChange = async (id, isAssigned) => {
    try {
      await axios.patch(
        `https://api.lifesignify.com/api/admin/updatestatus/${id}`,
        {
          isAssigned,
        }
      );
      fetchAdmins();
    } catch (error) {
      console.error("Error updating assignment", error);
    }
  };

  return (
    <div className="admin-container">
      <h2>Admin Management</h2>

      <form className="admin-form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="username"
          value={formData.username}
          onChange={handleInputChange}
          placeholder="Username"
          required
        />
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          placeholder="Password"
          required
        />
        <button type="submit">Add Admin</button>
      </form>

      <h3 style={{ textAlign: "center" }}>All Admins</h3>
      <table className="admin-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Username</th>
            <th>Created At</th>
            <th>Assign Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {admins.map((admin) => (
            <tr key={admin._id}>
              <td>{admin._id}</td>
              <td>{admin.username}</td>
              <td>
                {new Date(admin.createdAt).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </td>

              <td>
                <select
                  value={admin.isAssigned}
                  onChange={(e) =>
                    handleAssignChange(admin._id, e.target.value === "true")
                  }
                >
                  <option value={false}>Not Assigned</option>
                  <option value={true}>Assigned</option>
                </select>
              </td>

              <td>
                <button onClick={() => handleDelete(admin._id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Admin;
