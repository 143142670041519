import React, { useState, useEffect } from "react";
import "../styles/Testimonial.css";
import axios from "axios";

const Testimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    avatar: "",
    quote: "",
    role: "",
    videoLink: "",
  });
  const [editingTestimonial, setEditingTestimonial] = useState(null);

  const extractFileId = (rawURL) => {
    const RAW_URL1 = rawURL?.split("/d/");
    const RAW_URL2 = RAW_URL1[1]?.split("/view");
    const IMAGE_ID = RAW_URL2[0];
    return `https://drive.google.com/thumbnail?id=${IMAGE_ID}`;
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    try {
      const response = await axios.get(
        "https://api.lifesignify.com/api/testimonial"
      );
      setTestimonials(response.data);
    } catch (error) {
      console.error("Failed to fetch testimonials", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "avatar") {
      const extractedUrl = extractFileId(value);
      setFormData({ ...formData, [name]: extractedUrl });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (editingTestimonial) {
      try {
        const response = await axios.put(
          `https://api.lifesignify.com/api/testimonial/${editingTestimonial._id}`,
          formData
        );
        setTestimonials(
          testimonials.map((testimonial) =>
            testimonial._id === editingTestimonial._id
              ? response.data
              : testimonial
          )
        );
        setEditingTestimonial(null); // Reset editing mode
        setFormData({
          name: "",
          avatar: "",
          quote: "",
          role: "",
          videoLink: "",
        }); // Reset form
      } catch (error) {
        console.error("Failed to update testimonial", error);
      }
    } else {
      // If adding new, create the testimonial
      try {
        const response = await axios.post(
          "https://api.lifesignify.com/api/testimonial",
          formData
        );
        setTestimonials([...testimonials, response.data]);
        setFormData({
          name: "",
          avatar: "",
          quote: "",
          role: "",
          videoLink: "",
        }); // Reset form
      } catch (error) {
        console.error("Failed to add testimonial", error);
      }
    }
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.lifesignify.com/api/testimonial/${id}`);
      setTestimonials(
        testimonials.filter((testimonial) => testimonial._id !== id)
      );
    } catch (error) {
      console.error("Failed to delete testimonial", error);
    }
  };

  // Handle edit
  const handleEdit = (testimonial) => {
    setEditingTestimonial(testimonial); // Set the testimonial being edited
    setFormData({
      name: testimonial.name,
      avatar: testimonial.avatar,
      quote: testimonial.quote,
      role: testimonial.role,
      videoLink: testimonial.videoLink,
    }); // Pre-fill form data with testimonial details
  };

  return (
    <div className="testimonial-container">
      <h2>{editingTestimonial ? "Edit Testimonial" : "Add Testimonial"}</h2>
      <form className="testimonial-form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Name"
          required
        />
        <input
          type="url"
          name="avatar"
          value={formData.avatar}
          onChange={handleInputChange}
          placeholder="Avatar URL"
          disabled={editingTestimonial}
          required
        />
        <input
          type="text"
          name="role"
          value={formData.role}
          onChange={handleInputChange}
          placeholder="Role"
          required
        />
        <textarea
          name="quote"
          value={formData.quote}
          onChange={handleInputChange}
          placeholder="Quote"
          required
        ></textarea>
        <input
          type="url"
          name="videoLink"
          value={formData.videoLink}
          onChange={handleInputChange}
          placeholder="Video Link"
        />
        <button type="submit">
          {editingTestimonial ? "Update Testimonial" : "Add Testimonial"}
        </button>
      </form>

      <h2>Testimonials</h2>
      <table className="testimonial-table">
        <thead>
          <tr>
            <th>Avatar</th>
            <th>Name</th>
            <th>Quote</th>
            <th>Role</th>
            <th>Video</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {testimonials.map((testimonial) => (
            <tr key={testimonial._id}>
              <td>
                <img src={testimonial?.avatar} alt={testimonial.name} />
              </td>
              <td>{testimonial.name}</td>
              <td>{testimonial.quote}</td>
              <td>{testimonial.role}</td>
              <td>
                {testimonial.videoLink ? (
                  <a
                    href={testimonial.videoLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Watch Video
                  </a>
                ) : (
                  "No Video"
                )}
              </td>
              <td>
                <button onClick={() => handleDelete(testimonial._id)}>
                  Delete
                </button>
                <button
                  style={{ backgroundColor: "green", marginTop: 7 }}
                  className="editBtbTestimonial"
                  onClick={() => handleEdit(testimonial)}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Testimonial;
