import React, { useState, useEffect } from "react";
import { FaChevronDown, FaChevronRight, FaEdit, FaTrash } from "react-icons/fa";
import "../styles/Courses.css";

const Courses = () => {
  const [courseName, setCourseName] = useState("");
  const [courseImage, setCourseImage] = useState("");
  const [coursePrice, setCoursePrice] = useState("");
  const [courseDriveLink, setCourseDriveLink] = useState("");
  const [chapters, setChapters] = useState([]);
  const [courses, setCourses] = useState([]);
  const [chapterName, setChapterName] = useState("");
  const [subChapterName, setSubChapterName] = useState("");
  const [subChapterLink, setSubChapterLink] = useState("");
  const [isCourseExpanded, setIsCourseExpanded] = useState(false);
  const [expandedChapters, setExpandedChapters] = useState({});
  const [expandedAllCourses, setExpandedAllCourses] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [courseId, setCourseId] = useState(null);
  const [editSubChapterIndex, setEditSubChapterIndex] = useState({
    chapter: null,
    subChapter: null,
  });

  const fetchCourses = async () => {
    try {
      const response = await fetch("https://api.lifesignify.com/api/courses");
      const data = await response.json();
      setCourses(data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const extractFileId = (rawURL) => {
    if (rawURL.includes("thumbnail?id=")) {
      return rawURL;
    }
    const RAW_URL1 = rawURL?.split("/d/");
    const RAW_URL2 = RAW_URL1[1]?.split("/view");
    const IMAGE_ID = RAW_URL2[0];
    return `https://drive.google.com/thumbnail?id=${IMAGE_ID}`;
  };

  function convertGoogleDriveLinkToIframe(link) {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?drive\.google\.com\/file\/d\/([a-zA-Z0-9_-]+)\/(view|preview)/;
    const match = link.match(regex);

    if (match && match[1]) {
      const fileId = match[1];
      return `https://drive.google.com/file/d/${fileId}/preview`;
    }

    return null;
  }

  const handleAddOrUpdateCourse = async () => {
    if (courseName && courseImage && chapters.length > 0 && coursePrice) {
      const newCourse = {
        name: courseName,
        image: extractFileId(courseImage),
        driveLink: courseDriveLink,
        chapters,
        price: coursePrice,
      };

      const method = editMode ? "PUT" : "POST";
      const endpoint = editMode
        ? `https://api.lifesignify.com/api/courses/${courseId}`
        : "https://api.lifesignify.com/api/courses/create";

      try {
        const response = await fetch(endpoint, {
          method,
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newCourse),
        });

        if (response.ok) {
          await fetchCourses();
          resetForm();
          alert(
            editMode
              ? "Successfully updated course!"
              : "Successfully added course!"
          );
        } else {
          console.error("Failed to add/update course");
        }
      } catch (error) {
        console.error("Error adding/updating course:", error);
      }
    }
  };

  const handleDeleteCourse = async (id) => {
    try {
      const response = await fetch(
        `https://api.lifesignify.com/api/courses/${id}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        fetchCourses();
        alert("Course deleted successfully!");
      } else {
        console.error("Failed to delete course");
      }
    } catch (error) {
      console.error("Error deleting course:", error);
    }
  };

  const handleEditCourse = (course) => {
    setCourseId(course._id);
    setCourseName(course.name);
    setCourseImage(course.image);
    setCoursePrice(course.price);
    setCourseDriveLink(course.driveLink);
    setChapters(course.chapters);
    setIsCourseExpanded(true);
    setEditMode(true);
  };

  const resetForm = () => {
    setCourseName("");
    setCourseImage("");
    setCoursePrice("");
    setCourseDriveLink("");
    setChapters([]);
    setIsCourseExpanded(false);
    setEditMode(false);
    setCourseId(null);
    setEditSubChapterIndex({ chapter: null, subChapter: null });
  };

  const toggleCourse = () => {
    setIsCourseExpanded(!isCourseExpanded);
  };

  const handleAddChapter = () => {
    if (chapterName) {
      setChapters([...chapters, { name: chapterName, subChapters: [] }]);
      setChapterName("");
    }
  };

  const handleAddSubChapter = (chapterIndex) => {
    if (subChapterName && subChapterLink) {
      const updatedChapters = [...chapters];
      updatedChapters[chapterIndex].subChapters.push({
        name: subChapterName,
        link: convertGoogleDriveLinkToIframe(subChapterLink),
      });
      setChapters(updatedChapters);
      setSubChapterName("");
      setSubChapterLink("");
    }
  };

  const handleEditSubChapter = (chapterIndex, subChapterIndex) => {
    setEditSubChapterIndex({
      chapter: chapterIndex,
      subChapter: subChapterIndex,
    });
    const subChapter = chapters[chapterIndex].subChapters[subChapterIndex];
    setSubChapterName(subChapter.name);
    setSubChapterLink(subChapter.link);
  };

  const handleSaveSubChapter = (chapterIndex, subChapterIndex) => {
    const updatedChapters = [...chapters];
    updatedChapters[chapterIndex].subChapters[subChapterIndex] = {
      name: subChapterName,
      link: subChapterLink,
    };
    setChapters(updatedChapters);
    setEditSubChapterIndex({ chapter: null, subChapter: null });
    setSubChapterName("");
    setSubChapterLink("");
  };

  return (
    <div className="courses-container">
      <h1>{editMode ? "Edit Course" : "Add Course"}</h1>
      <form className="course-form">
        <input
          type="text"
          placeholder="Course Name"
          value={courseName}
          onChange={(e) => setCourseName(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Course Image URL"
          value={courseImage}
          onChange={(e) => setCourseImage(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Course Price"
          value={coursePrice}
          onChange={(e) => setCoursePrice(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Course Drive Link"
          value={courseDriveLink}
          onChange={(e) => setCourseDriveLink(e.target.value)}
          required
        />
        <div className="chapter-inputs">
          <input
            type="text"
            placeholder="Chapter Name"
            value={chapterName}
            onChange={(e) => setChapterName(e.target.value)}
            required
          />
          <button type="button" onClick={handleAddChapter}>
            Add Chapter
          </button>
        </div>
      </form>

      {courseName && chapters.length > 0 && (
        <div className="courses-list">
          <h2>Courses List</h2>
          <div className="course-card">
            <div className="course-header" onClick={toggleCourse}>
              <h3>{courseName}</h3>
              {isCourseExpanded ? <FaChevronDown /> : <FaChevronRight />}
            </div>
            {isCourseExpanded && (
              <div className="chapters">
                {chapters.map((chapter, chapterIndex) => (
                  <div key={chapterIndex} className="chapter-card">
                    <div className="chapter-header">
                      <h4>{chapter.name}</h4>
                      <input
                        type="text"
                        placeholder="Sub-Chapter Name"
                        value={subChapterName}
                        onChange={(e) => setSubChapterName(e.target.value)}
                      />
                      <input
                        type="text"
                        placeholder="Video / Pdf Link"
                        value={subChapterLink}
                        onChange={(e) => setSubChapterLink(e.target.value)}
                      />
                      <button
                        type="button"
                        onClick={() => handleAddSubChapter(chapterIndex)}
                      >
                        Add Sub-Chapter
                      </button>
                    </div>
                    <ul className="sub-chapter-list">
                      {chapter.subChapters.map((subChapter, subIndex) => (
                        <li key={subIndex}>
                          {editSubChapterIndex.chapter === chapterIndex &&
                          editSubChapterIndex.subChapter === subIndex ? (
                            <div>
                              <input
                                type="text"
                                value={subChapterName}
                                onChange={(e) =>
                                  setSubChapterName(e.target.value)
                                }
                              />
                              <input
                                type="text"
                                value={subChapterLink}
                                onChange={(e) =>
                                  setSubChapterLink(e.target.value)
                                }
                              />
                              <button
                                onClick={() =>
                                  handleSaveSubChapter(chapterIndex, subIndex)
                                }
                              >
                                Save
                              </button>
                            </div>
                          ) : (
                            <span
                              onClick={() =>
                                window.open(subChapter.link, "_blank")
                              }
                            >
                              {subIndex + 1}. {subChapter.name}
                            </span>
                          )}
                          <button
                            onClick={() =>
                              handleEditSubChapter(chapterIndex, subIndex)
                            }
                          >
                            <FaEdit />
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            )}
            <button onClick={handleAddOrUpdateCourse}>
              {editMode ? "Update Course" : "Add Course"}
            </button>
          </div>
        </div>
      )}

      <div className="all-courses">
        <h2>All Courses</h2>
        {courses.map((course, index) => (
          <div key={course._id} className="course-card">
            <div
              className="course-header"
              onClick={() =>
                setExpandedAllCourses({
                  ...expandedAllCourses,
                  [index]: !expandedAllCourses[index],
                })
              }
            >
              <h3>
                {index + 1}. {course.name}
              </h3>
              {expandedAllCourses[index] ? (
                <FaChevronDown />
              ) : (
                <FaChevronRight />
              )}
            </div>
            {expandedAllCourses[index] && (
              <>
                <div className="course-image">
                  <img src={course?.image} alt={course.name} />
                  <p>Price: {course.price}</p>
                </div>
                <div className="chapters">
                  {course.chapters.map((chapter, chapterIndex) => (
                    <div key={chapterIndex} className="chapter-card">
                      <h4>
                        {chapterIndex + 1}. {chapter.name}
                      </h4>
                      <ul className="sub-chapter-list">
                        {chapter.subChapters.map((subChapter, subIndex) => (
                          <li key={subIndex}>
                            <span
                              onClick={() =>
                                window.open(subChapter.link, "_blank")
                              }
                            >
                              {subIndex + 1}. {subChapter.name}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                <button
                  style={{ marginTop: 10, marginBottom: 5 }}
                  onClick={() => handleEditCourse(course)}
                >
                  Edit
                </button>
                <button
                  style={{ backgroundColor: "red" }}
                  onClick={() => handleDeleteCourse(course._id)}
                >
                  Delete
                </button>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Courses;
