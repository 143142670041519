import React, { useEffect, useState } from "react";
import "../styles/Consultation.css";

const Consultation = () => {
  const [consultations, setConsultations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [consultationFees, setConsultationFees] = useState(0);
  const [newFees, setNewFees] = useState("");

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://api.lifesignify.com/api/consultations"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch consultations");
      }
      const data = await response.json();
      setConsultations(data);

      const feesResponse = await fetch(
        "https://api.lifesignify.com/api/consultationfee"
      );
      if (!feesResponse.ok) {
        throw new Error("Failed to fetch consultation fees");
      }
      const feesData = await feesResponse.json();
      setConsultationFees(feesData.amount);
    } catch (err) {
      console.error(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Update consultation fees
  const updateFees = async () => {
    try {
      const response = await fetch(
        "https://api.lifesignify.com/api/consultationfee",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ amount: parseFloat(newFees) }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update consultation fees");
      }
      const updatedData = await response.json();
      setConsultationFees(updatedData.fees);
      fetchData();
      setNewFees("");
    } catch (err) {
      console.error(err.message);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="consultation-container">
      <h1>Consultations</h1>
      <div className="fees-container">
        <p>
          Current Consultation Fees: <strong>₹{consultationFees}</strong>
        </p>
        <input
          type="number"
          value={newFees}
          onChange={(e) => setNewFees(e.target.value)}
          placeholder="Enter new fees"
        />
        <button onClick={updateFees}>Update Fees</button>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Message</th>
            <th>TransactionId</th>
            <th>Payment Status</th>
          </tr>
        </thead>
        <tbody>
          {consultations.map((consultation, index) => (
            <tr key={index}>
              <td>{consultation?.firstName}</td>
              <td>{consultation?.phone}</td>
              <td>{consultation?.email}</td>
              <td>{consultation?.message}</td>
              <td>{consultation?.transactionId}</td>
              <td>{consultation?.paymentStatus}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Consultation;
