import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import Users from "./Users";
import Orders from "./Orders";
import Courses from "./Courses";
import Books from "./Books";
import Consultation from "./Consultation";
import Webinar from "./Webinar";
import Team from "./Team";
import { RxHamburgerMenu } from "react-icons/rx";
import "../styles/Content.css";
import Dhyanam from "./Dhyanam";
import Testimonial from "./Testimonial";
import Admin from "./Admin";

const Content = ({ toggleSidebar }) => {
  return (
    <div
      style={{
        flexGrow: 1,
        minHeight: "100vh",
        backgroundColor: "rgb(222, 222, 222)",
        padding: "20px",
      }}
    >
      <RxHamburgerMenu
        className="hamburger-icon"
        color="black"
        size={30}
        onClick={toggleSidebar}
      />
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/users" element={<Users />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/books" element={<Books />} />
        <Route path="/consultation" element={<Consultation />} />
        <Route path="/webinar" element={<Webinar />} />
        <Route path="/team" element={<Team />} />
        <Route path="/dhyanam" element={<Dhyanam />} />
        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
    </div>
  );
};

export default Content;
