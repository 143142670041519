import React, { useEffect, useState } from "react";
import "../styles/Users.css";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(
          "https://api.lifesignify.com/api/auth/users"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }
        const data = await response.json();
        setUsers(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="users">
      <h1>User Management</h1>
      <table className="users-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Username</th>
            <th>Email</th>
            <th className="hideSmall">Registered On</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            const createdAt = new Date(user.createdAt);
            const options = {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            };
            const formattedDate = createdAt.toLocaleDateString(
              "en-GB",
              options
            );

            return (
              <tr key={user._id}>
                <td>{user._id}</td>
                <td>{user.email?.split("@")[0]}</td>
                <td>{user.email}</td>
                <td className="hideSmall">{formattedDate}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Users;
