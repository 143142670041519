import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/Dhyanam.css";

const Dhyanam = () => {
  const [products, setProducts] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    image: "",
  });
  const [editingProduct, setEditingProduct] = useState(null); // To handle editing state

  const extractFileId = (rawURL) => {
    const RAW_URL1 = rawURL?.split("/d/");
    const RAW_URL2 = RAW_URL1[1]?.split("/view");
    const IMAGE_ID = RAW_URL2[0];
    return `https://drive.google.com/thumbnail?id=${IMAGE_ID}`;
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        "https://api.lifesignify.com/api/dhyanam"
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const addProduct = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.lifesignify.com/api/dhyanam",
        formData
      );
      setProducts([...products, response.data]);
      setFormData({ name: "", price: "", image: "" });
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  const deleteProduct = async (id) => {
    try {
      await axios.delete(`https://api.lifesignify.com/api/dhyanam/${id}`);
      setProducts(products.filter((product) => product._id !== id));
      alert("Deleted successfully!");
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const updateProduct = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `https://api.lifesignify.com/api/dhyanam/${editingProduct._id}`,
        formData
      );
      const updatedProducts = products.map((product) =>
        product._id === editingProduct._id ? response.data : product
      );
      setProducts(updatedProducts);
      setEditingProduct(null);
      setFormData({ name: "", price: "", image: "" });
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "image") {
      const extractedUrl = extractFileId(value);
      setFormData({
        ...formData,
        [name]: extractedUrl,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className="dhyanam-container">
      <h2>{editingProduct ? "Edit Dhyanam Product" : "Add Dhyanam Product"}</h2>
      <form
        onSubmit={editingProduct ? updateProduct : addProduct}
        className="dhyanam-form"
      >
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Product Name"
          required
        />
        <input
          type="number"
          name="price"
          value={formData.price}
          onChange={handleChange}
          placeholder="Product Price"
          required
        />
        <input
          type="text"
          name="image"
          value={formData.image}
          onChange={handleChange}
          placeholder="Image URL"
          required
        />
        <button style={{ backgroundColor: "#555" }} type="submit">
          {editingProduct ? "Update Product" : "Add Product"}
        </button>
      </form>

      <h2 style={{ textAlign: "center", marginTop: 20 }}>Dhyanam Products</h2>
      <table className="dhyanam-table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Price</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product._id}>
              <td>{product._id}</td>
              <td>{product.name}</td>
              <td>₹{product.price}</td>
              <td>
                <img src={product?.image} alt={product.name} width="50" />
              </td>
              <td>
                <button
                  className="deleteBtnOfDhayanm"
                  onClick={() => deleteProduct(product._id)}
                >
                  Delete
                </button>
                <button
                  className="editBtnOfDhayanm"
                  onClick={() => {
                    setEditingProduct(product);
                    setFormData({
                      name: product.name,
                      price: product.price,
                      image: product.image,
                    });
                  }}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Dhyanam;
