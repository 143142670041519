import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/Webinar.css";

const Webinar = () => {
  const [formData, setFormData] = useState({
    poster: "",
    date: "",
    time: "",
    duration: "",
    title: "",
    titleDesc: "",
    reasons: [
      {
        reason: "",
        subReason: "",
      },
    ],
  });

  const [webinars, setWebinars] = useState([]);

  const extractFileId = (rawURL) => {
    const RAW_URL1 = rawURL?.split("/d/");
    const RAW_URL2 = RAW_URL1[1]?.split("/view");
    const IMAGE_ID = RAW_URL2[0];
    return `https://drive.google.com/thumbnail?id=${IMAGE_ID}`;
  };

  const fetchWebinars = async () => {
    try {
      const response = await axios.get(
        "https://api.lifesignify.com/api/webinarad"
      );
      setWebinars(response.data.webinars);
    } catch (error) {
      console.error("Error fetching webinars", error);
    }
  };

  useEffect(() => {
    fetchWebinars();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "poster") {
      const extractedUrl = extractFileId(value);
      setFormData((prevData) => ({
        ...prevData,
        [name]: extractedUrl,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleReasonChange = (index, e) => {
    const { name, value } = e.target;
    const updatedReasons = [...formData.reasons];
    updatedReasons[index] = {
      ...updatedReasons[index],
      [name]: value,
    };
    setFormData({ ...formData, reasons: updatedReasons });
  };

  const handleAddReason = () => {
    setFormData((prevData) => ({
      ...prevData,
      reasons: [
        ...prevData.reasons,
        {
          reason: "",
          subReason: "",
        },
      ],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form data:", formData);

    const isValid = formData.reasons.every(
      (reason) => reason.reason && reason.subReason
    );
    if (!isValid) {
      alert("Please fill out both reason and subreason for each reason.");
      return;
    }

    try {
      await axios.post("https://api.lifesignify.com/api/webinarad", formData);
      fetchWebinars();
      setFormData({
        poster: "",
        date: "",
        time: "",
        duration: "",
        title: "",
        titleDesc: "",
        reasons: [{ reason: "", subReason: "" }],
      });
    } catch (error) {
      console.error("Error adding webinar", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.lifesignify.com/api/webinarad/${id}`);
      setWebinars(webinars.filter((webinar) => webinar._id !== id));
      fetchWebinars();
    } catch (error) {
      console.error("Error deleting webinar", error);
    }
  };

  return (
    <div className="webinar-container">
      <h2>Add a New Webinar</h2>
      <form onSubmit={handleSubmit} className="webinar-form">
        <div className="form-group">
          <label htmlFor="poster">Poster (URL)</label>
          <input
            type="text"
            id="poster"
            name="poster"
            value={formData.poster}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="date">Date</label>
          <input
            type="date"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="time">Time</label>
          <input
            type="time"
            id="time"
            name="time"
            value={formData.time}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="duration">Duration (in hours)</label>
          <input
            type="number"
            id="duration"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="titleDesc">Title Description</label>
          <textarea
            id="titleDesc"
            name="titleDesc"
            value={formData.titleDesc}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Why You Can't Miss This Webinar</label>
          {formData.reasons.map((reason, index) => (
            <div key={index} className="reason-input">
              <input
                type="text"
                name="reason"
                value={reason.reason}
                onChange={(e) => handleReasonChange(index, e)}
                placeholder={`Reason #${index + 1}`}
                required
              />
              <input
                type="text"
                name="subReason"
                value={reason.subReason}
                onChange={(e) => handleReasonChange(index, e)}
                placeholder={`subReason #${index + 1}`}
                required
              />
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddReason}
            className="add-reason-btn"
          >
            Add Another Reason
          </button>
        </div>
        <button type="submit" className="submit-btn">
          Submit
        </button>
      </form>

      <h2 style={{ marginTop: 20 }}>All Webinars</h2>
      <div className="webinars-list">
        {webinars.map((webinar) => (
          <div className="webinar-card" key={webinar._id}>
            <div className="webinar-poster">
              <img src={webinar?.poster} alt={webinar.title} />
            </div>
            <div className="webinar-info">
              <h3>{webinar.title}</h3>
              <p>{webinar.titleDesc}</p>
              <p>
                {webinar.date} at {webinar.time}
              </p>
              <p>Duration: {webinar.duration} hours</p>
              <ul>
                {webinar.reasons.map((reason, index) => (
                  <li key={index}>
                    {reason.reason} - {reason.subReason}
                  </li>
                ))}
              </ul>
            </div>
            <button
              className="delete-btn"
              onClick={() => handleDelete(webinar._id)}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Webinar;
